<template>
  <DrupalLink v-if="url" :to="url">
    <slot :url="url" :title="title" :description="description">
      <span class="hyphens-auto font-bold col-start-2 row-start-1"
        >{{ title }}
        <span v-if="doesStartDownload" class="sr-only">
          ({{
            $texts('accessibility.startsDownload', 'Startet einen Download')
          }})
        </span>
      </span>

      <p
        v-if="!hideDescription && description"
        class="hyphens-auto col-start-2 row-start-2 font-normal mt-5 text-base"
      >
        {{ description }}
      </p>
    </slot>
  </DrupalLink>
</template>

<script lang="ts" setup>
import type {
  FieldItemTypeLinkDescriptionEntity_MediaDocument_Fragment,
  FieldItemTypeLinkDescriptionEntityFragment,
  FieldItemTypeLinkDescriptionFragment,
} from '#graphql-operations'

const props = defineProps<{
  linkTitle?: FieldItemTypeLinkDescriptionFragment['linkTitle']
  linkDescription?: FieldItemTypeLinkDescriptionFragment['linkDescription']
  uri?: FieldItemTypeLinkDescriptionFragment['uri']
  hideDescription?: boolean
}>()

const entity = computed<FieldItemTypeLinkDescriptionEntityFragment | undefined>(
  () => {
    if (props.uri && 'entity' in props.uri) {
      return props.uri.entity
    }
    return undefined
  },
)

const title = computed(() => {
  // Use provided link title.
  if (props.linkTitle) {
    return props.linkTitle
  }

  // Use teaserTitle from linked entity.
  if (
    entity.value &&
    'teaserTitle' in entity.value &&
    entity.value.teaserTitle
  ) {
    return entity.value.teaserTitle
  }

  // Fallback to shwowing the URL. Better than nothing.
  return url.value
})

const description = computed(() => {
  // Use custom description if provided.
  if (props.linkDescription) {
    return props.linkDescription
  }

  if (entity.value) {
    // Use description from media document.
    // @TODO: Should be provided by GraphQL as teaserText on MediaDocument.
    if (entity.value?.__typename === 'MediaDocument') {
      if (entity.value.document?.description) {
        return entity.value.document.description
      }
      // Use teaserText of linked entity.
    } else if ('teaserText' in entity.value && entity.value.teaserText) {
      return entity.value.teaserText
    }
  }
  return ''
})

function isToMediaDocument(
  entity: FieldItemTypeLinkDescriptionEntityFragment | undefined,
): entity is FieldItemTypeLinkDescriptionEntity_MediaDocument_Fragment {
  return !!entity && entity.__typename === 'MediaDocument'
}

function hasOriginalFileUrl(
  entity: FieldItemTypeLinkDescriptionEntityFragment | undefined,
) {
  if (!isToMediaDocument(entity)) {
    return false
  }

  return 'originalFileUrl' in entity && entity.originalFileUrl
}

function hasMediaFileUrl(
  entity: FieldItemTypeLinkDescriptionEntityFragment | undefined,
) {
  if (!isToMediaDocument(entity)) {
    return false
  }

  return 'mediaFileUrl' in entity && entity.mediaFileUrl?.path
}

const doesStartDownload = computed(() => {
  return hasOriginalFileUrl(entity.value) || hasMediaFileUrl(entity.value)
})

const url = computed(() => {
  if (entity.value && isToMediaDocument(entity.value)) {
    // Check if is Media and of type Document.
    if (hasOriginalFileUrl(entity.value)) {
      return entity.value.originalFileUrl
    }

    // Check if media file url exists and is not empty.
    if (hasMediaFileUrl(entity.value)) {
      return entity.value.mediaFileUrl?.path
    }
  }

  return props.uri?.path
})
</script>
